import { useQuery } from 'react-query';
import { supabase } from '@/utils/supabase-client';

const fetchCustomFonts = async () => {
  const user = supabase.auth.user();
  const { data, error } = await supabase
    .from('fonts')
    .select('*')
    .eq('user_id', user.id)
    .order('created_at', { ascending: true });

  if (error) {
    // console.log('fetchCustomFonts error: ' + error.message);
    throw error;
  }

  return data;
};

const useCustomFonts = () => {
  return useQuery('customFonts', () => fetchCustomFonts());
};

export { useCustomFonts, fetchCustomFonts };
