import React, { useState } from 'react';
import { Box, Container, Text, Image } from '@chakra-ui/react';
import { useFontsState } from '../hooks/fonts';
import ImageView from './ImageView';
import { useImage } from 'hooks/useImage';

const FontItem = React.memo((props) => {
  // const fontNameLowercase = (props.fontName + '').toLowerCase();
  const [borderColor, setBorderColor] = useState(null);

  const { addFontToFilter } = useFontsState();

  return (
    <>
      <Box
        cursor="pointer"
        // maxWidth="350px"
        flexGrow="1"
        flexShrink="1"
        borderRadius="md"
        boxShadow="2xl"
        m={3}
        background="white"
        padding={3}
        variant="outline"
        // display={props.font.selected ? 'block' : 'none'}
        //TODO
        // border={borderColor}
        onClick={(e) => {
          if (borderColor === null) {
            setBorderColor('3px solid orange');
            // addFontToFilter(props.font.id, true);
          } else {
            setBorderColor(null);
            // addFontToFilter(props.font.id, false);
          }
        }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @font-face { font-family: '${props.font.font_family_alias}'; src: url('${props.font.url}');
          `
          }}
        />

        <Text fontSize="lg" color="grey" textAlign="left">
          {props.type === 'custom'
            ? // ? props.font.font_family + '.' + props.font.font_type
              props.font.display_name
            : props.font.font_family}
        </Text>
        {props.image ? (
          <Container textAlign="center" position="relative" maxWidth="300px">
            <Image
              // boxSize="600px"
              width="100%"
              src={props.image}
            />
            <Text
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily={props.font.font_family_alias}
              fontSize={props.fontSize}
              textTransform={props.textCase}
              color={props.color ? props.color : '#000000'}
              whiteSpace="pre-wrap"
            >
              {props.text}
            </Text>
          </Container>
        ) : props.imageId ? (
          <Container textAlign="center" position="relative" maxWidth="300px">
            <ImageView imageId={props.imageId} />
            {/* <Image
              // boxSize="600px"
              width="100%"
              src="https://fontvisual-dev.s3.amazonaws.com/d80b7783-fdcf-417e-9959-e23926e45a5a/images/mockup2.jpg"
            /> */}
            <Text
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily={props.font.font_family_alias}
              fontSize={props.fontSize}
              textTransform={props.textCase}
              color={props.color ? props.color : '#000000'}
              whiteSpace="pre-wrap"
            >
              {props.text}
            </Text>
          </Container>
        ) : (
          <Box
            textAlign="center"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            fontFamily={props.font.font_family_alias}
            fontSize={props.fontSize}
            textTransform={props.textCase}
            color={props.color ? props.color : '#000000'}
            p={2}
            whiteSpace="pre-wrap"
          >
            {props.text}
          </Box>
        )}
      </Box>

      {/* <div className={styles.fontItem}>
        <h1 className={styles.fontName}>{fontNameLowercase}</h1>
        <div style={props.style}>{props.text}</div>
      </div> */}
    </>
  );
});

export default FontItem;
