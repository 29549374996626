import { SITE_DOMAIN } from '@/utils/constants';
import { Text, HStack, Box } from '@chakra-ui/react';
import Image from 'next/image';
import fontvisual from '../public/fontvisual.png';

const ShowcaseFooter = () => {
  return (
    <HStack align="center" justify="center" mt={16} mb={4}>
      {/* <Box> */}
      <Text fontStyle="italic" fontSize="sm" color="gray.500">
        Powered by
      </Text>
      {/* </Box> */}
      {/* <p> */}
      <a href="https://fontvisual.com?ref=watermark">
        <Image
          src={fontvisual}
          alt="FontVisual"
          width="117px"
          height="27px"
          justify="center"
        />
      </a>
      {/* </p> */}
    </HStack>
  );
};

export default ShowcaseFooter;
