import Showcase from '@/components/Showcase';
import ShowcaseHeader from '@/components/ShowcaseHeader';
import { supabase } from '@/utils/supabase-client';
import ShowcaseFooter from '@/components/ShowcaseFooter';
import { useRouter } from 'next/router';
import { Spinner } from '@chakra-ui/react';
import { supabaseAdmin } from '@/utils/supabase-admin';
import { ShowcaseLayouts } from '@/lib/constants';
import ShowcaseLayoutA from '@/components/sections/showcase/ShowcaseLayoutA';
import ShowcaseLayoutB from '@/components/sections/showcase/ShowcaseLayoutB';
import ShowcaseLayoutC from '@/components/sections/showcase/ShowcaseLayoutC';
import ShowcaseLayoutD from '@/components/sections/showcase/ShowcaseLayoutD';

const ShowcasePage = ({
  showcase,
  fonts,
  logoImage,
  image,
  titleFont,
  colors,
  colorOption,
  settings
}) => {
  // const router = useRouter()
  // const { id } = router.query
  const router = useRouter();
  // console.log('showcase', showcase);
  // console.log('settings', settings);
  // console.log('fonts', fonts);

  // re-order fonts based on showcase.font_ids order
  const arrayMap =
    fonts &&
    fonts.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue
      }),
      {}
    );
  const orderedFonts =
    showcase && showcase.font_ids.map((id) => arrayMap[id]).filter((e) => e); //filter for null, when some fonts have been removed
  // console.log('orderedFonts', orderedFonts);

  const ShowcaseLayout = () => {
    if (!showcase.settings_layout) {
      //v1.x Showcases
      return (
        <>
          {/* <section className="layout"> */}
          <ShowcaseHeader name={showcase.name} />
          <Showcase
            isShowcaseDemo={true}
            initialText={showcase.text}
            // fontIds={showcase.font_ids}
            fontList={fonts}
            // imageId={showcase.image_id}
            image={image ? image.url : null}
          ></Showcase>
          {showcase.branding != 'remove' ? <ShowcaseFooter /> : null}
          {/* </section> */}
        </>
      );
    }
    switch (showcase.settings_layout) {
      //v2.x Showcases
      case ShowcaseLayouts.DEFAULT.key: {
        return (
          <ShowcaseLayoutA
            logoImage={logoImage ? logoImage.url : null}
            website={showcase.website}
            displayTitle={showcase.settings_display_title}
            name={showcase.name}
            titleFont={titleFont}
            titleFontSize={showcase.settings_title_font_size}
            text={showcase.text}
            textSize={showcase.settings_text_size}
            fontList={orderedFonts}
            image={image ? image.url : null}
            branding={settings && settings.branding}
            colors={colors}
            colorOption={colorOption}
            displayTextCase={showcase.settings_display_text_case}
            onlyUppercase={showcase.settings_only_uppercase}
            theme={showcase.settings_theme}
            displayTextSize={showcase.settings_display_text_size}
          />
        );
      }
      case ShowcaseLayouts.LAYOUTB.key: {
        return (
          <ShowcaseLayoutB
            logoImage={logoImage ? logoImage.url : null}
            website={showcase.website}
            displayTitle={showcase.settings_display_title}
            name={showcase.name}
            titleFont={titleFont}
            titleFontSize={showcase.settings_title_font_size}
            text={showcase.text}
            textSize={showcase.settings_text_size}
            fontList={orderedFonts}
            image={image ? image.url : null}
            branding={settings && settings.branding}
            colors={colors}
            colorOption={colorOption}
            displayTextCase={showcase.settings_display_text_case}
            onlyUppercase={showcase.settings_only_uppercase}
            theme={showcase.settings_theme}
            displayTextSize={showcase.settings_display_text_size}
          />
        );
      }
      case ShowcaseLayouts.LAYOUTC.key: {
        return (
          <ShowcaseLayoutC
            logoImage={logoImage ? logoImage.url : null}
            website={showcase.website}
            displayTitle={showcase.settings_display_title}
            name={showcase.name}
            titleFont={titleFont}
            titleFontSize={showcase.settings_title_font_size}
            text={showcase.text}
            textSize={showcase.settings_text_size}
            fontList={orderedFonts}
            image={image ? image.url : null}
            branding={settings && settings.branding}
            colors={colors}
            colorOption={colorOption}
            displayTextCase={showcase.settings_display_text_case}
            onlyUppercase={showcase.settings_only_uppercase}
            theme={showcase.settings_theme}
            displayTextSize={showcase.settings_display_text_size}
          />
        );
      }
      case ShowcaseLayouts.LAYOUTD.key: {
        return (
          <ShowcaseLayoutD
            logoImage={logoImage ? logoImage.url : null}
            website={showcase.website}
            displayTitle={showcase.settings_display_title}
            name={showcase.name}
            titleFont={titleFont}
            titleFontSize={showcase.settings_title_font_size}
            text={showcase.text}
            textSize={showcase.settings_text_size}
            fontList={orderedFonts}
            image={image ? image.url : null}
            branding={settings && settings.branding}
            colors={colors}
            colorOption={colorOption}
            displayTextCase={showcase.settings_display_text_case}
            onlyUppercase={showcase.settings_only_uppercase}
            theme={showcase.settings_theme}
            displayTextSize={showcase.settings_display_text_size}
          />
        );
      }
      default: {
        //v1.x Showcases
        return (
          <>
            {/* <section className="layout"> */}
            <ShowcaseHeader name={showcase.name} />
            <Showcase
              isShowcaseDemo={true}
              initialText={showcase.text}
              // fontIds={showcase.font_ids}
              fontList={fonts}
              // imageId={showcase.image_id}
              image={image ? image.url : null}
            ></Showcase>
            {showcase.branding != 'remove' ? <ShowcaseFooter /> : null}
            {/* </section> */}
          </>
        );
      }
    }
  };

  // console.log('showcase', showcase);
  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (!showcase || showcase.status == 'expired') {
    return <div>Showcase does not exist</div>;
  }

  if (router.isFallback) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    );
  }

  return <ShowcaseLayout />;
};

export const getStaticPaths = async () => {
  // const { user } = await supabase.auth.api.getUserByCookie(req);
  // const user = supabase.auth.user();
  // console.log('fetchShowcases user: ', user);

  const { data, error } = await supabase.from('showcases').select('id');
  // .eq('user_id', user.id);

  // if (error) {
  //   console.log('fetchShowcases error: ' + error.message);
  //   // throw error;
  // }

  // console.log('fetchShowcases data: ', data);

  const paths = data.map((showcase) => ({
    params: { id: showcase.id }
  }));

  // const showcases = fetchShowcases();
  // // Get the paths we want to pre-render based on showcases
  // const paths = showcases.map((showcase) => ({
  //   params: { id: showcase.id }
  // }));

  // console.log('paths', paths);

  // We'll pre-render only these paths at build time.
  // { fallback: false } means other routes should 404.
  // { fallback: blocking } will server-render pages
  // on-demand if the path doesn't exist.
  return { paths, fallback: 'blocking' };
};

// This also gets called at build time
export async function getStaticProps({ params }) {
  // console.log('params', params);
  // console.log('params.id ' + params.id);
  // params contains the post `id`.
  // If the route is like /posts/1, then params.id is 1
  // const showcase = fetchShowcaseById(params.id);
  // const res = await fetch(`https://.../posts/${params.id}`)
  // const post = await res.json()

  // const user = supabase.auth.user();
  const { data, error } = await supabase
    .from('showcases')
    .select('*')
    // .eq('user_id', user.id)
    .eq('id', params.id)
    .single();

  // if (error) {
  //   console.log('fetchShowcaseById error: ' + error.message);
  //   // throw error;
  // }

  // console.log('showcase', data);

  if (data) {
    // get list of fonts
    const { data: fonts, error: errorFonts } = await supabaseAdmin
      .from('fonts')
      .select('*')
      .in('id', data.font_ids);

    // if (errorFonts) {
    //   console.log('errorFonts: ' + errorFonts.message);
    //   // throw errorFonts;
    // }

    // console.log('fonts', fonts);

    const showcaseFonts = fonts.map((font) => {
      var newFont = { ...font };
      newFont.selected = true;
      return newFont;
    });

    // console.log('showcaseFonts', showcaseFonts);

    // get image
    const { data: image, error: errorImage } = await supabaseAdmin
      .from('images')
      .select('url')
      .eq('id', data.image_id ? data.image_id : 0)
      .maybeSingle();

    // if (errorImage) {
    //   console.log('errorImage: ' + errorImage.message);
    //   // throw errorImage;
    // }

    // console.log('image', image);

    // get logo image
    const { data: logoImage, error: errorLogoImage } = await supabaseAdmin
      .from('images')
      .select('url')
      .eq('id', data.logo_image_id ? data.logo_image_id : 0)
      .maybeSingle();

    // get title font
    const { data: titleFont, error: errorTitleFont } = await supabase
      .from('fonts')
      .select('*')
      .eq('id', data.settings_title_font_id)
      .single();

    // if (error) {
    //   console.log('titleFont error: ' + errorTitleFont.message);
    //   // throw error;
    // }

    // console.log('titleFont', titleFont);

    // get settings
    const { data: settings, error: errorSettings } = await supabaseAdmin
      .from('settings')
      .select('*')
      .eq('user_id', data.user_id)
      .single();
    // .in('id', data.settings_color_ids);

    // if (errorSettings) {
    //   console.log('errorSettings: ' + errorSettings.message);
    //   // throw errorFonts;
    // }

    // console.log('settings', settings);

    if (data.settings_display_colors) {
      // get list of colors
      if (settings && settings.showcase_color_option === 'Custom') {
        // get list of colors
        const { data: colors, error: errorColors } = await supabaseAdmin
          .from('colors')
          .select('hex,label')
          .eq('user_id', data.user_id)
          .order('created_at', { ascending: true });
        // .in('id', data.settings_color_ids);

        // if (errorColors) {
        //   console.log('errorColors: ' + errorColors.message);
        //   // throw errorFonts;
        // }

        // console.log('colors', colors);

        // Pass showcase data to the page via props
        return {
          props: {
            showcase: data,
            fonts: showcaseFonts,
            logoImage,
            image,
            titleFont,
            colors,
            colorOption: settings.showcase_color_option,
            settings
          }
        };
      } else {
        // Pass showcase data to the page via props
        return {
          props: {
            showcase: data,
            fonts: showcaseFonts,
            logoImage,
            image,
            titleFont,
            colorOption: settings.showcase_color_option,
            settings
          }
        };
      }
    }

    // Pass showcase data to the page via props
    return {
      props: {
        showcase: data,
        fonts: showcaseFonts,
        logoImage,
        image,
        titleFont,
        settings
      }
    };
  }

  return { props: {} };
}

export default ShowcasePage;
