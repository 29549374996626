import React, { useState, useRef, useEffect } from 'react';
import FontItem from './FontItem';
import { useCustomFonts } from 'hooks/useCustomFonts';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Text,
  Tooltip
} from '@chakra-ui/react';
// import { CirclePicker } from 'react-color';

const Showcase = (props) => {
  // console.log('rendering Showcase...');
  const defaultText = props.initialText ? props.initialText : 'Your text here';
  const [text, setText] = useState(defaultText);
  const [fontSize, setFontSize] = useState(20);
  const [color, setSelectedColor] = useState('#000000');
  let selectedFonts = [];

  useEffect(() => {
    // console.log('useEffect');
    setFontSize(fontSize); // set new value when value gets changed, even when first render
  }, [fontSize]);

  function handleChange(event) {
    if (event.target.value == '') {
      setText(defaultText);
    } else {
      setText(event.target.value);
    }
  }

  function handleSliderChange(val) {
    setFontSize(val);
  }

  function handleChangeComplete(color) {
    // console.log('color', color.hex);
    setSelectedColor(color.hex);
  }

  // console.log('selectedFonts', selectedFonts);
  return (
    <Box m={{ base: '5', md: '50' }} px={8}>
      <Center>
        <Input
          p={2}
          m={4}
          backgroundColor="gray.100"
          maxWidth={{ base: 'full', md: '600px' }}
          borderRadius="full"
          placeholder={text}
          variant="outline"
          // border="2px"
          borderColor="gray.500"
          focusBorderColor="primary.400"
          onChange={handleChange}
          size={'lg'}
        />
      </Center>

      <Center>
        <Tooltip label="Font size" aria-label="Font size">
          <Text>{fontSize}px</Text>
        </Tooltip>
        <Slider
          aria-label="font size"
          size="lg"
          width={{ base: '200px', md: '400px' }}
          ml={4}
          mb={4}
          defaultValue={fontSize}
          colorScheme="darkpastelgreen"
          min={10}
          max={100}
          step={10}
          onChange={handleSliderChange}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={4}>
            <Box />
          </SliderThumb>
        </Slider>{' '}
      </Center>

      <Center>
        {/* {props.displayColor ? (
          <CirclePicker
            color={color}
            onChangeComplete={handleChangeComplete}
            width="350px"
            colors={[
              'black',
              'gray',
              'silver',
              'maroon',
              'red',
              'purple',
              'fuchsia',
              'green',
              'lime',
              'olive',
              'yellow',
              'navy',
              'blue',
              'teal',
              'aqua',
              'orange'
            ]}
            // colors={[
            //   '#000000',
            //   '#c0c0c0',
            //   '#808080',
            //   '#ffffff',
            //   '#800000',
            //   '#ff0000',
            //   '#800080',
            //   '#ff00ff',
            //   '#008000',
            //   '#00ff00',
            //   '#808000',
            //   '#ffff00',
            //   '#000080',
            //   '#0000ff',
            //   '#008080',
            //   '#00ffff'
            // ]}
            triangle="hide"
          />
        ) : null} */}
      </Center>

      <Flex
        flexFlow="row wrap"
        // justifyContent="space-around"
        padding={{ base: '5', md: '10' }}
      >
        {!props.isShowcaseDemo && selectedFonts
          ? selectedFonts.length > 0
            ? React.Children.toArray(
                selectedFonts
                  // .filter((font) => props.fontIds.includes(font.id))
                  .map((font) => (
                    <FontItem
                      type="custom"
                      // key={font.font_family_alias}
                      text={text}
                      font={font}
                      textCase="capitalize"
                      // fontName={font.fontFamily}
                      // fontFamily={font.fontFamilyAlias}
                      fontSize={fontSize + 'px'}
                      // style={{
                      //   fontFamily: font.fontFamilyAlias,
                      //   fontSize: fontSize + 'px'
                      // }}
                      imageId={props.imageId}
                      color={color}
                    />
                  ))
              )
            : 'No Fonts Available'
          : ''}
        {props.isShowcaseDemo
          ? props.fontList
            ? props.fontList.length > 0
              ? props.fontList.map((font) => (
                  <FontItem
                    type="custom"
                    key={font.font_family_alias}
                    text={text}
                    font={font}
                    // fontName={font.fontFamily}
                    // fontFamily={font.fontFamilyAlias}
                    fontSize={fontSize + 'px'}
                    // style={{
                    //   fontFamily: font.fontFamilyAlias,
                    //   fontSize: fontSize + 'px'
                    // }}
                    image={props.image}
                    color={color}
                  />
                ))
              : 'No Fonts Available'
            : ''
          : ''}
      </Flex>
    </Box>
  );
};

export default Showcase;
