import { Center, Flex, Heading } from '@chakra-ui/react';

const ShowcaseHeader = ({ name }) => {
  return (
    <Center mt={8}>
      <Heading
        as={'h1'}
        alignItems="center"
        justifyContent="center"
        fontSize={{ base: 'xl', md: '3xl' }}
        fontWeight={'normal'}
      >
        {name}
      </Heading>
    </Center>
  );
};

export default ShowcaseHeader;
